import { CaretRight, ArrowRight } from '@phosphor-icons/react';
import React from 'react';
import { useTheme, useMediaQuery, styled } from '@mui/material';
import { Stack } from '@mui/system';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { SwContentLayout } from '@lib/components/SwContentLayout';
import { SwRichContent } from '@lib/components/SwRichContent';
import { SwSharingLinksSection } from '@lib/components/SwSharingLinksSection';
import { SwPaper } from '@lib/components/bedrock/SwPaper';
import { colors } from '@lib/components/bedrock/fundations/colors';
import { SwContainer } from '@lib/components/bedrock/layout/SwContainer';
import SwFlexBox from '@lib/components/bedrock/layout/SwFlexBox';
import { SwGrid } from '@lib/components/bedrock/layout/SwGrid';
import SwLine from '@lib/components/bedrock/layout/SwLine';
import { SwInternalLink } from '@lib/components/bedrock/navigation/SwInternalLink';
import { SwLinkBox, SwLinkOverlay } from '@lib/components/bedrock/navigation/SwLinkBox';
import SwTypography from '@lib/components/bedrock/typography/SwTypography';
import { SwCallToActionTwoColumns } from '@lib/components/call-to-action/SwCallToActionTwoColumns';
import { WEBSITE_MENU_ITEM } from '@lib/constants/routing';
import { AspectRatios } from '@lib/enums/apectRatios';
import Articles from '@app/articles/Articles';
import WebsiteSectionsLayout from '@app/common/components/WebisteSectionsLayout';
import { ProfileMeta } from '@app/profiles/ProfileMeta';
import FlashBanner from '../app/common/components/FlashBanner';
import Seo from '../app/common/components/Seo';
import SwLayout from '../components/v1/SwLayout';
import SwPagination from '../components/v1/pagination/SwPagination';
import SwBreadcrumb from '../components/v1/website/SwBreadcrumb';
import SwTopNav from '../components/v1/website/SwTopNav';
import SwWebsiteFooter from '../components/v1/website/SwWebsiteFooter';
import { grey3, grey5, grey6 } from '../lib/components/bedrock/SwColors';
import SwWebsiteContainer from '../lib/components/layout/SwWebsiteContainer';

const Header = styled('div')`
    padding-block-start: 32px;
`;

const Portrait = styled('div')`
    position: relative;
    width: 100%;
    aspect-ratio: ${AspectRatios.landscape};
    background: ${grey5};
    border-radius: 8px;
    z-index: 0;
    border: 1px solid ${grey5};
    overflow: hidden;
`;

const SidebarContainer = styled('div')`
    display: flex;
    gap: 16px;
    flex-direction: column;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
        padding-inline: 20px;
    }
`;

const Story = ({ data, pageContext: { slug, basePath } }) => {
    const profile = data.graphCmsProfile;
    const isTabletOrLess = useMediaQuery(useTheme().breakpoints.down('md'));
    const articles = data.allGraphCmsArticle.nodes;
    const { currentPage, pageCount } = data.allGraphCmsArticle.pageInfo;
    const fullName = `${profile.person.firstName} ${profile.person.lastName}`;

    return (
        <>
            <FlashBanner banner={data.flashBanners.edges[0].node} />
            <SwTopNav
                activePage={WEBSITE_MENU_ITEM.resources.key}
                highlightedPage={data.highlightedPage?.nodes?.at(0)}
                latestUpdates={data.latestArticles.nodes}
            />
            <SwWebsiteContainer>
                <SwLayout background={grey6}>
                    <SwBreadcrumb basePage={WEBSITE_MENU_ITEM.resources.text} page={WEBSITE_MENU_ITEM.profiles.text} />
                    <WebsiteSectionsLayout>
                        <div>
                            <SwContainer>
                                <SwGrid columns={1} gap={16}>
                                    <Header>
                                        <SwGrid columns={{ xs: 1, md: 2 }} gap={32}>
                                            {profile.person.portrait ? (
                                                <Portrait>
                                                    <GatsbyImage
                                                        alt={profile.person.portrait.alt}
                                                        image={profile.person.portrait.gatsbyImageData}
                                                        objectFit={'cover'}
                                                        style={{ height: '100%', width: '100%' }}
                                                    />
                                                </Portrait>
                                            ) : null}

                                            <Stack spacing={1}>
                                                <SwTypography component={'h1'} variant={'h2'}>
                                                    {fullName}
                                                </SwTypography>
                                                <SwTypography
                                                    color={colors.text.secondary}
                                                    component={'h2'}
                                                    variant={'h4'}
                                                >
                                                    {profile.person.role}
                                                </SwTypography>
                                            </Stack>
                                        </SwGrid>
                                    </Header>
                                </SwGrid>
                                <SwLine spacing={4} />
                            </SwContainer>
                            <SwContainer paddingInline={{ xs: '0px' }} style={{ minHeight: '180px' }}>
                                <SwContentLayout
                                    leftSide={
                                        <SidebarContainer>
                                            <ProfileMeta
                                                collapsible={isTabletOrLess}
                                                sweepStartDate={profile.person.sweepStartDate}
                                                tags={profile.person.tags}
                                            />
                                        </SidebarContainer>
                                    }
                                    rightSide={
                                        <SidebarContainer>
                                            <SwSharingLinksSection slug={slug} title={fullName} />
                                        </SidebarContainer>
                                    }
                                >
                                    <SwContentLayout.Main>
                                        {profile.content || profile.person.bio ? (
                                            <SwPaper display={'flex'} flexDirection={'column'} gap={2} p={4}>
                                                {Boolean(profile.person.bio) && (
                                                    <SwTypography
                                                        color={colors.text.default}
                                                        component={'p'}
                                                        variant={'headingStrong'}
                                                    >
                                                        {profile.person.bio}
                                                    </SwTypography>
                                                )}
                                                {Boolean(profile.content) && (
                                                    <SwRichContent
                                                        content={profile.content.json}
                                                        references={profile.content.references}
                                                    />
                                                )}
                                            </SwPaper>
                                        ) : (
                                            <SwPaper bgcolor={colors.surface.background}>
                                                <SwTypography color={colors.text.secondary} variant={'body2'}>
                                                    Bio not available
                                                </SwTypography>
                                            </SwPaper>
                                        )}
                                    </SwContentLayout.Main>
                                </SwContentLayout>
                            </SwContainer>
                            <SwContainer id={'content'}>
                                <SwFlexBox alignItems={'start'} flexDirection={'column'} gap={16} width={'100%'}>
                                    <SwLine color={grey5} direction={'horizontal'} spacingBottom={0} spacingTop={4} />
                                    <SwFlexBox alignItems={'center'} gap={4}>
                                        <SwTypography bold={true} color={grey3} component={'h2'} variant={'body1'}>
                                            {profile.person.firstName}'s content
                                        </SwTypography>
                                        <CaretRight color={grey3} fontWeight={'bold'} />
                                    </SwFlexBox>
                                    {articles.length > 0 ? <Articles articles={articles} /> : null}
                                    {pageCount > 1 && (
                                        <>
                                            <SwLine spacing={2} spacingBottom={0} />
                                            <SwPagination
                                                basePath={basePath}
                                                currentPage={currentPage ?? 1}
                                                pageCount={pageCount}
                                                target={`#content`}
                                            />
                                        </>
                                    )}
                                </SwFlexBox>
                                <SwLine spacing={4} spacingBottom={2} />
                                <Stack alignItems={'start'} spacing={2}>
                                    <SwFlexBox alignItems={'center'} gap={4}>
                                        <SwTypography bold={true} color={grey3} component={'h2'} variant={'body1'}>
                                            Jobs
                                        </SwTypography>
                                        <CaretRight color={grey3} fontWeight={'bold'} />
                                    </SwFlexBox>
                                    <SwCallToActionTwoColumns {...data.callOut} />
                                    <SwGrid columns={{ xs: 1, sm: 2, md: 4 }}>
                                        {data.highlightedJobs.nodes.map((job) => (
                                            <SwLinkBox key={job.id}>
                                                <SwPaper
                                                    display={'flex'}
                                                    flexDirection={'column'}
                                                    gap={1}
                                                    height={'100%'}
                                                    interactive={true}
                                                    key={job.id}
                                                >
                                                    <SwTypography component={'h3'} variant={'bodyStrong'}>
                                                        <SwLinkOverlay
                                                            to={`${job.links.careersite_job_apply_iframe_url.replace('/applications/new?iframe=true', '')}`}
                                                        >
                                                            {job.title}
                                                        </SwLinkOverlay>
                                                    </SwTypography>
                                                </SwPaper>
                                            </SwLinkBox>
                                        ))}
                                    </SwGrid>
                                    <SwInternalLink to={WEBSITE_MENU_ITEM.jobs.link} variant={'secondary'}>
                                        More <ArrowRight />
                                    </SwInternalLink>
                                </Stack>
                            </SwContainer>
                        </div>
                    </WebsiteSectionsLayout>
                    <SwWebsiteFooter newsletterForm={data.newsletterForm} />
                </SwLayout>
            </SwWebsiteContainer>
        </>
    );
};

export const Head = ({
    location: { pathname },
    data: {
        graphCmsProfile: { seo }
    }
}) => (
    <Seo
        description={seo.description}
        image={seo.image?.url}
        noIndex={seo.noIndex}
        pathname={pathname}
        title={seo.title}
    />
);

export const getStoryQuery = graphql`
    query getProfileQuery($id: String!, $personId: String!, $limit: Int, $skip: Int) {
        latestArticles: allGraphCmsArticle(
            filter: { locale: { eq: en }, industry: { id: { eq: null } }, seo: { noIndex: { ne: true } } }
            sort: { order: DESC, fields: published }
            limit: 4
        ) {
            nodes {
                ...ArticleCard_GraphCMS_Article
            }
        }
        highlightedJobs: allTeamtailorJob(limit: 4) {
            nodes {
                id
                title
                links {
                    careersite_job_apply_iframe_url
                }
            }
        }
        newsletterForm: hubspotForm(name: { eq: "Newsletter Sign-Up" }) {
            ...Essentials_HubspotForm
        }
        highlightedPage: allGraphCmsPage(
            limit: 1
            filter: { isNewPage: { eq: true } }
            sort: { order: DESC, fields: createdAt }
        ) {
            nodes {
                ...HighlightedPage_GraphCMS_Page
            }
        }
        flashBanners: allGraphCmsFlashBanner(
            sort: { fields: createdAt, order: DESC }
            filter: { locale: { eq: en } }
            limit: 1
        ) {
            edges {
                node {
                    ...Essentials_GraphCMS_FlashBanner
                }
            }
        }
        allGraphCmsArticle(
            skip: $skip
            limit: $limit
            filter: { authors: { elemMatch: { id: { eq: $personId } } }, seo: { noIndex: { ne: true } } }
        ) {
            nodes {
                ...ArticleCard_GraphCMS_Article
            }
            pageInfo {
                currentPage
                pageCount
                perPage
                totalCount
            }
        }
        callOut: graphCmsCallToAction(name: { eq: "Jobs CTA" }) {
            ...Essentials_GraphCMS_CallToAction
        }
        graphCmsProfile(id: { eq: $id }) {
            id
            slug
            locale
            person {
                ...Essentials_GraphCMS_Person
                bio
                portrait {
                    id
                    alt
                    gatsbyImageData(height: 400, aspectRatio: 1.778, layout: CONSTRAINED)
                }
            }
            content {
                json
                references {
                    ...Essentials_GraphCMS_Asset
                    ...Essentials_GraphCMS_Quote
                    ...Essentials_GraphCMS_CustomerQuote
                    ...Essentials_GraphCMS_CallOut
                }
            }
            seo {
                ...Essentials_GraphCMS_Seo
            }
        }
    }
`;

export default Story;
