import React from 'react';
import { styled } from '@mui/material';
import dayjs from 'dayjs';
import { SwCollapsibleSection } from '@lib/components/SwCollapsibleSection';
import { grey3, grey7 } from '@lib/components/bedrock/SwColors';
import { SwPaper } from '@lib/components/bedrock/SwPaper';
import SwTag, { SwTagColors } from '@lib/components/bedrock/badges/SwTag';
import SwFlexBox from '@lib/components/bedrock/layout/SwFlexBox';
import SwTypography from '@lib/components/bedrock/typography/SwTypography';

export type ProfileMetaProps = {
    sweepStartDate?: string;
    tags: Array<{ label: string; color: SwTagColors; id: string }>;
    collapsible?: boolean;
};

const DescriptionList = styled('dl')`
    & > dd:not(:last-child) {
        padding-block-end: 8px;
    }
`;

const TagsContainer = styled(SwFlexBox)`
    margin-block-start: 4px;
`;

const Container = styled('div')`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(1) + 'px'};
`;

const ProfileMetaContent: React.FC<Omit<ProfileMetaProps, 'collapsible'>> = (props) => {
    const { sweepStartDate, tags, ...rest } = props;

    return (
        <Container {...rest}>
            <DescriptionList>
                {sweepStartDate ? (
                    <>
                        <SwTypography bold={true} color={grey3} component={'dt'} variant={'caption'}>
                            Joined Sweep
                        </SwTypography>
                        <SwTypography component={'dd'}>{dayjs(sweepStartDate).format('MMMM YYYY')}</SwTypography>
                    </>
                ) : null}
                {tags.length ? (
                    <>
                        <SwTypography bold={true} color={grey3} component={'dt'} variant={'caption'}>
                            Expertise
                        </SwTypography>
                        <SwTypography component={'dd'}>
                            <TagsContainer flexDirection={'row'} flexWrap={'wrap'} gap={8}>
                                {tags.map((tag) => (
                                    <SwTag color={tag.color} key={tag.id} size={'small'} uppercase={true}>
                                        {tag.label}
                                    </SwTag>
                                ))}
                            </TagsContainer>
                        </SwTypography>
                    </>
                ) : null}
            </DescriptionList>
        </Container>
    );
};

export const ProfileMeta: React.FC<ProfileMetaProps> = (props) => {
    const { collapsible, ...rest } = props;

    return collapsible ? (
        <SwCollapsibleSection
            title={
                <SwTypography bold={true} color={grey3} component={'h2'} variant={'caption'}>
                    Details
                </SwTypography>
            }
        >
            <ProfileMetaContent {...rest} />
        </SwCollapsibleSection>
    ) : (
        <SwPaper bgcolor={grey7}>
            <ProfileMetaContent {...rest} />
        </SwPaper>
    );
};
