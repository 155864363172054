import React from 'react';
import { Stack } from '@mui/material';
import { GatsbyImage, GatsbyImageProps } from 'gatsby-plugin-image';
import { AspectRatios } from '@lib/enums/apectRatios';
import SwMediaCard, { SwMediaCardProps } from '../SwMediaCard';
import { colors } from '../bedrock/fundations/colors';
import SwFlexBox from '../bedrock/layout/SwFlexBox';
import { SwInternalLink } from '../bedrock/navigation/SwInternalLink';
import SwTypography from '../bedrock/typography/SwTypography';
import { CallToActionVariant } from './constants';

export type SwCallToActionTwoColumnsProps = {
    title: string;
    subtitle?: string;
    actionLabel: string;
    variant: CallToActionVariant;
    image?: {
        alt: string;
        gatsbyImageData: GatsbyImageProps['image'];
    };
    link: {
        externalUrl?: string;
        target: {
            slug: string;
        };
    };
};

export const SwCallToActionTwoColumns: React.FC<SwCallToActionTwoColumnsProps> = (props) => {
    const { title, subtitle, actionLabel, link, image, variant } = props;

    const hasExternalUrl = link.externalUrl?.match(/^https?:\/\/|^\/\//i);

    return (
        <SwMediaCard
            aspectRatio={AspectRatios.golden}
            background={'white'}
            layout={variant.replace('_', '-') as SwMediaCardProps['layout']}
            media={
                <GatsbyImage
                    alt={image.alt}
                    image={image.gatsbyImageData}
                    objectFit={'cover'}
                    style={{ width: '100%', height: '100%' }}
                />
            }
        >
            <Stack alignItems={'start'} height={'100%'} justifyContent={'space-between'} spacing={2}>
                <SwFlexBox flexDirection={'column'} gap={16}>
                    <SwTypography component={'p'} variant={'h4'}>
                        {title}
                    </SwTypography>
                    <SwTypography color={colors.text.secondary} component={'p'} variant={'body2'}>
                        {subtitle}
                    </SwTypography>
                </SwFlexBox>
                <SwInternalLink
                    href={link.externalUrl}
                    size={'large'}
                    target={hasExternalUrl ? '_blank' : undefined}
                    to={link.target ? `/${link.target.slug}` : undefined}
                    variant={'default'}
                >
                    {actionLabel}
                </SwInternalLink>
            </Stack>
        </SwMediaCard>
    );
};
